import { ref } from "@vue/composition-api";
import { useGet } from "feathers-vuex";
import Vue from "vue";

import { snackbar } from "@/components/base/snackbar/useSnackbar";
import { useRouter } from "@/helpers";
import { store } from "@/store";

const { CloseOutLetter, NoticeToProceed, Project } = Vue.$FeathersVuex.api;

export function useProject() {
  const { route } = useRouter();

  const {
    item: project,
    hasLoaded: projectHasLoaded,
    isPending: projectIsPending
  } = useGet({
    model: Project,
    id: route.value.params.project
  });

  return {
    project,
    projectHasLoaded,
    projectIsPending
  };
}

export function getProjectCount() {
  const projectCount = ref(0);

  try {
    let params = { query: {} };
    store.dispatch("projects/count", params).then((response) => {
      projectCount.value = response;
    });
  } catch (error) {
    console.log(error);
  }

  return {
    projectCount
  };
}

// NOTE: Projects that are called once using `useFind` or `useGet` may or may
// not stay in the store for the duration of the session.
export function getProjectFromStore(project) {
  const projectRecord = Project.getFromStore(project);
  return {
    projectRecord
  };
}

/**
 * Create a document object
 * @param {*} id
 * @param {*} reportType
 * @param {*} title
 * @param {*} fulcrumId
 * @param {*} description
 * @param {*} date
 * @returns
 */
function createDocument(id, reportType, title, fulcrumId, description, date) {
  return {
    id,
    reportType,
    title,
    shouldEmail: false,
    fulcrumId,
    documentDescription: description,
    dateCreated: date
  };
}

/**
 * Resolve Project Documents List
 *
 * @description Returns an array of documents that are available for download
 * for given project.
 *
 * @param {*} projectRecord
 */
export function resolveProjectDocumentsList(projectRecord) {
  console.log(projectRecord);

  const projectDocuments = ref([]);
  const {
    project_phase,
    status,
    project_type,
    fulcrum_id,
    proceed_date,
    close_out_date
  } = projectRecord;

  if (project_phase !== "Construction") {
    return;
  }

  const isClosedOut = status === "Closed Out";
  const isTrafficType = project_type === "Traffic";

  const documents = [];

  // NTP letter is common to all
  documents.push(
    createDocument(
      1,
      "NTP",
      "NTP Letter",
      fulcrum_id,
      "Download a copy of the NTP letter",
      proceed_date
    )
  );

  // For Traffic types and non-closed out projects
  if (isTrafficType && !isClosedOut) {
    documents.push(
      createDocument(
        2,
        "PR",
        "Press Release Email",
        fulcrum_id,
        "Download a copy of the PR notification email",
        proceed_date
      )
    );
  }

  // For Closed Out projects
  if (isClosedOut) {
    documents.push(
      createDocument(
        documents.length + 1,
        "CloseOut",
        "Close Out Letter",
        fulcrum_id,
        "Download a copy of the Close Out letter",
        close_out_date ? close_out_date : null
      )
    );

    // If it's a Traffic type, add the Press Release Email
    if (isTrafficType) {
      documents.push(
        createDocument(
          documents.length + 1,
          "PR",
          "Press Release Email",
          fulcrum_id,
          "Download a copy of the PR notification email",
          proceed_date
        )
      );
    }
  }

  projectDocuments.value = documents;

  const countNewProjectsWithin14Days = (projectDocuments) => {
    const currentDate = new Date();
    const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

    const newProjectDocuments = projectDocuments.value.filter((document) => {
      const documentDate = new Date(document.dateCreated);
      return documentDate >= fourteenDaysAgo && documentDate <= currentDate;
    });

    return newProjectDocuments.length;
  };

  const newProjectCount = countNewProjectsWithin14Days(projectDocuments);

  return { projectDocuments, newProjectCount };
}

/**
 * Add a new Construction Record to the Project. We will need to grab the project's
 * `fulcrum_id` and add it to each permit that was selected.
 *  We must transform:
 *      [
 *        {
 *          fulcrum_id: "<something>",
 *          permit_desc: "<something>",
 *          permit_id: "<something>",
 *          permit_type: "<need to fix to display all elements of array>"
 *        }
 *      ]
 *
 *  To the Construction Record model
 */
export function useAssignPermitDialog() {
  let isPermitListEditDialogOpen = ref(false);
  let isUpdatingPermitAssignment = ref(false);

  const loading = ref(false);
  //const { route } = useRouter();

  const assignPermit = (constructionRecords) => {
    isPermitListEditDialogOpen.value = true;
    if (constructionRecords.length > 0) {
      isUpdatingPermitAssignment.value = true;
    }
    //console.log(constructionRecords);
  };

  const processFormData = async (form) => {
    try {
      loading.value = true;

      // const projectFulcrumId = route.value.params.project
      // console.log("Inside processFormData");
      // console.log(projectFulcrumId);
      console.log(form);
      // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~");
      let permits = form.selectedPermits;
      // console.log(permits);
      let constructionRecords = permits.map(
        ({ permit_id, fulcrum_id, permit_desc, permit_type }) => ({
          permit_num: permit_id,
          permit_record: fulcrum_id,
          permit_desc: permit_desc,
          permit_type: permit_type.join(),
          project_record: form.project_record,
          project_num: form.project_num,
          project_name: form.project_name,
          status: "TS Not Started"
        })
      );
      if (isUpdatingPermitAssignment.value == false) {
        // console.log(constructionRecords);
        // Instead of sending individual records to the server we will send the array
        // and do the rest of the processing from there.
        store
          .dispatch("construction-records/create", {
            constructionRecords: constructionRecords
          })
          .then(() => {
            snackbar({
              text: `Permits successfully assigned to this project.`,
              color: "success"
            });
          });
        // constructionRecords.forEach(record => {
        //   let constructionRecord = new ConstructionRecord({});
        //   let constructionRecordClone = constructionRecord.clone();
        //   Object.assign(constructionRecordClone, record);
        //   constructionRecordClone.create().then((result) => { console.log(`${constructionRecordClone.fulcrum_id} saved!`)});

        // })
        //console.log(constructionRecords);
        // console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~");
      } else {
        store
          .dispatch("construction-records/update", {
            constructionRecords: constructionRecords
          })
          .then(() => {
            snackbar({
              text: `Assigned permits list updated.`,
              color: "success"
            });
          });
      }
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to assign permits to this project. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      loading.value = false;
    }
  };
  const closeAssignPermitDialog = (isEditDialogOpen) => {
    isPermitListEditDialogOpen.value = isEditDialogOpen;
  };
  return {
    isPermitListEditDialogOpen,
    isUpdatingPermitAssignment,
    assignPermit,
    processFormData,
    closeAssignPermitDialog
  };
}

/**
 * Resolve Missing NTP Letter Fields
 * The missing fields are found in the `vw_ntp_letter` view.
 *
 */
export function resolveMissingNtpFields(projectId) {
  const { item: ntp, hasLoaded: ntpHasLoaded } = useGet({
    model: NoticeToProceed,
    id: projectId
  });

  return {
    ntp,
    ntpHasLoaded
  };
}

/**
 * Resolve Missing Close Out Letter Fields
 * The missing fields are found in the `vw_close_out_letter` view.
 *
 */
export function resolveMissingCloseOutFields(projectId) {
  const { item: closeOut, hasLoaded: closeOutHasLoaded } = useGet({
    model: CloseOutLetter,
    id: projectId
  });

  return {
    closeOut,
    closeOutHasLoaded
  };
}

export function useProjectAddDialog() {
  const isProjectAddDialogOpen = ref(false);
  const loading = ref(false);
  const redirectOnSuccess = ref(true);
  let projectClone = ref({});
  let project = new Project({});
  projectClone = project.clone();

  const createItem = (editDialogType) => {
    // when a new project is created, it must have the following
    // default values.
    let project = new Project({
      project_id: "TPM - Planned",
      hold_type: "None",
      status: "TS Not Started"
    });
    projectClone = project.clone();
    if (editDialogType === "new") {
      isProjectAddDialogOpen.value = true;
    }
  };

  const processFormData = async (form) => {
    try {
      //loading.value = true;
      Object.assign(projectClone, form);
      // eslint-disable-next-line no-unused-vars
      projectClone.commit(); // --> Save this record to the store?
      await projectClone.create();

      closeProjectAddDialog(false);
      store.dispatch("projects/updateLoading", false);
      snackbar({
        text: `${projectClone.proceed_name} was successfully created.`,
        color: "success"
      });
    } catch (error) {
      closeProjectAddDialog(false);
      snackbar({
        text: `Error encountered while trying to create this project. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      store.dispatch("projects/updateLoading", false);
      //location.reload();
    }
  };

  const closeProjectAddDialog = (isEditDialogOpen) => {
    if (isProjectAddDialogOpen.value == true) {
      isProjectAddDialogOpen.value = isEditDialogOpen;
    }
  };

  return {
    loading,
    redirectOnSuccess,
    createItem,
    isProjectAddDialogOpen,
    projectClone,
    processFormData,
    closeProjectAddDialog
  };
}

export function useProjectDialog() {
  const isProjectInfoDialogOpen = ref(false);
  const isProjectKeyDatesDialogOpen = ref(false);
  const isProjectTabFinancialDialogOpen = ref(false);
  const isProjectEditStatusDialogOpen = ref(false);
  const activeStep = ref(1);
  let itemToEdit = ref({});
  // const { emit } = getCurrentInstance();

  const loading = ref(false);

  const editItem = (item, editDialogType) => {
    itemToEdit.value = { ...item };
    if (editDialogType === "details") {
      isProjectInfoDialogOpen.value = true;
    } else if (editDialogType === "dates") {
      isProjectKeyDatesDialogOpen.value = true;
    } else if (editDialogType === "financial") {
      isProjectTabFinancialDialogOpen.value = true;
    } else if (editDialogType === "statusUpdate") {
      isProjectEditStatusDialogOpen.value = true;
    }
  };

  const processFormData = async (itemToEdit) => {
    try {
      // console.log(itemToEdit);
      // loading.value = true;
      let project = new Project({});
      let projectClone = project.clone();
      Object.assign(projectClone, itemToEdit);
      projectClone.commit();
      // console.log('value of projectClone: ', projectClone);
      if (projectClone.fulcrum_id) {
        // eslint-disable-next-line no-unused-vars
        const saveProject = await projectClone.patch();

        closeDialog(false);
        // console.log('value of saveProject: ', saveProject);
        activeStep.value = 1;
      }
    } catch (error) {
      closeDialog(false);
      store.dispatch("projects/updateLoading", false);
      snackbar({
        text: `Error encountered while trying to save this project. Please contact your Administrator.`,
        color: "error"
      });
    } finally {
      store.dispatch("projects/updateLoading", false);
      snackbar({
        text: `${itemToEdit.proceed_num} was successfully updated.`,
        color: "success"
      });
    }
  };

  const closeDialog = (isEditDialogOpen) => {
    if (isProjectInfoDialogOpen.value == true) {
      isProjectInfoDialogOpen.value = isEditDialogOpen;
    } else if (isProjectKeyDatesDialogOpen.value == true) {
      isProjectKeyDatesDialogOpen.value = isEditDialogOpen;
    } else if (isProjectTabFinancialDialogOpen.value == true) {
      isProjectTabFinancialDialogOpen.value = isEditDialogOpen;
    } else if (isProjectEditStatusDialogOpen.value == true) {
      isProjectEditStatusDialogOpen.value = isEditDialogOpen;
    }
  };

  return {
    isProjectInfoDialogOpen,
    isProjectKeyDatesDialogOpen,
    isProjectTabFinancialDialogOpen,
    isProjectEditStatusDialogOpen,
    activeStep,
    loading,
    itemToEdit,
    editItem,
    processFormData,
    closeDialog
  };
}
